import { Flex, Text, Heading, Box } from '@chakra-ui/react';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import React from 'react';
import SEO from '../components/seo';

function PrintMediaPage({
  pageContext: { heading, publication, date, image },
}) {
  return (
    <>
      <SEO title={`${heading}`} />
      <Flex flexDir="column" w="100%" mt={6}>
        <Text
          size="xs"
          color="primary.500"
          fontWeight="semibold"
          mb={3}
          letterSpacing={2}
          textTransform="uppercase"
        >
          {publication}
        </Text>
        <Heading size="2xl">{heading}</Heading>
        <Text size="sm" color="gray.500" fontWeight="semibold" mt={3}>
          {date}
        </Text>
        <Box w="50%" mt={6} mb={12}>
          <Img image={image} alt={heading} />
        </Box>
      </Flex>
    </>
  );
}

export default PrintMediaPage;
